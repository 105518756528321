import React from 'react'
import SearchData from '../Common/SearchData'

function LinkedinProfile() {

  return (
    <SearchData heading="Linkedin Profile Search Data" names="Profile" type="linkedin_profiles" />
  )
}

export default LinkedinProfile