import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser } from '../../Redux/Actions/AuthActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'

function Login() {
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const navigate = useNavigate()
  const [login, setLogin] = useState({
    email: "",
    password: ""
  })
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/')
    }
  }, [auth])

  const handleChange = (e) => {
    const { name, value } = e.target
    setLogin({
      ...login, [name]: value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    dispatch(loginUser(login, setLoader))
  }

  return (
    <div className='login-container'>
      <div className='container'>
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5">
            <div className="login-left">
              <div className="logo mb-4"><img src={require("../../images/logo.svg").default} alt="" /></div>
              <h3>Welcome to AiClients</h3>
              <p className='pt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
            </div>
          </div>

          <div className="col-lg-6">
            <form onSubmit={handleSubmit}>
              <div className="login-right">
                <div className='login-card'>
                  <div className="input-wrap">
                    <label className='label' htmlFor="">Email:</label>
                    <input className='input' type="email" placeholder='Enter Your Email' onChange={handleChange} value={login.email} name="email" required />
                  </div>
                  <div className="input-wrap mt-4">
                    <label className='label' htmlFor="" >Password:</label>
                    <input className='input' type="password" placeholder='Enter Your Password' onChange={handleChange} value={login.password} name="password" required />
                  </div>
                  <div className="input-wrap mt-4">
                    <button className='site-link lg full black mt-1'>Login  {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                  </div>
                  <p className='text-center pt-4'>Don't have account? <Link className='txt-orange' to="/register">Register </Link></p>
                  <p className='text-center pt-2'>Forgot Password? <Link className='txt-orange' to="/reset-password">Reset Password</Link></p>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div >
  )
}

export default Login