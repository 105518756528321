import React, { useState } from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";

import { PiMapPinArea } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import { RiMenuSearchLine } from "react-icons/ri";
import { PiQuotesDuotone } from "react-icons/pi";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

function Home() {

  return (
    <>
      <Header />

      <section className="welcome">
        <div className="container">
          <h2 className="text-center">Find Your Data <span className="gradient-txt">Effortlessly</span></h2>
          <h5 className="text-center mt-4">Discover the most efficient solution for extracting data online</h5>
          <div className="text-center mt-5">
            <a className="site-link alt" href=""><img src={require("../../images/icon/lightbulb-flash-fill.svg").default} alt="" /> Get Started For Free</a>
          </div>

          <h5 className="text-center mt-5 pt-4 font-gray">Powering the world's top data-driven teams</h5>
          <div className="info-box-group mt-5">
            <ul>
              <li>
                <div className="info-box">
                  <div className="row align-items-center">
                    <div className="col-auto"><img src={require("../../images/icon/Trophy.svg").default} alt="" /></div>
                    <div className="col">
                      <h5>1.5M+</h5>
                      <p>Data Row scraped</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="info-box green">
                  <div className="row align-items-center">
                    <div className="col-auto"><img src={require("../../images/icon/Users.svg").default} alt="" /></div>
                    <div className="col">
                      <h5>2.5M+</h5>
                      <p>Lead enrichment</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="info-box yellow">
                  <div className="row align-items-center">
                    <div className="col-auto"><img src={require("../../images/icon/Smiley.svg").default} alt="" /></div>
                    <div className="col">
                      <h5>183+</h5>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>


      <section className="client-logo">
        <div className="container">
          <div className="client-logo-in">
            <div className="client-logo-main">
              <h5>We have 23k+ Satisfied & Trusted Customers</h5>
              <ul>
                <li><img src={require("../../images/clients/Reddit.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Kickstarter.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Line.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/LinkedIN.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/KakaoTalk.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Mail_ru.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/ui8.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/MailChimp.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Marvel.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Mastercard.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Medium.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Messenger.svg").default} alt="" /></li>
                <li><img src={require("../../images/clients/Mi.svg").default} alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section className="data-solutions">
        <div className="container">
          <h2 className="text-center">Data Solutions for All Your Needs</h2>
          <h5 className="text-center pt-4">Empowering Your Decisions with Comprehensive Data Analysis</h5>
          <div className="row pt-5">

            <div className="col-sm-6 mt-4">
              <div className="data-box">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><PiMapPinArea /></div>
                  </div>
                  <div className="col">
                    <h4>Geo-Data Extraction</h4>
                    <p>Extract comprehensive information from any specified Google Map location URL to gain detailed insights about the area of interest.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-4">
              <div className="data-box purple">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><BsGraphUpArrow /></div>
                  </div>
                  <div className="col">
                    <h4>Market Research</h4>
                    <p>Acquire competitive data to analyze market trends and gain insights enabling strategic decision-making and enhanced market positioning.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-4">
              <div className="data-box yellow">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><RiMenuSearchLine /></div>
                  </div>
                  <div className="col">
                    <h4>Price Tracking</h4>
                    <p>Track prices across various websites to maintain competitive pricing strategies, identifying pricing trends to stay ahead of competitors.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-4">
              <div className="data-box green">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><BsGraphUpArrow /></div>
                  </div>
                  <div className="col">
                    <h4>Enriching CRM Data</h4>
                    <p>Gather content from multiple sources to create comprehensive datasets, integrating diverse information, enhance data accuracy.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-4">
              <div className="data-box blue">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><PiMapPinArea /></div>
                  </div>
                  <div className="col">
                    <h4>Lead Generation</h4>
                    <p>Generate quality leads by accessing various internet sources and taking advantage of big data by gathering comprehensive contact information.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 mt-4">
              <div className="data-box pink">
                <div className="row">
                  <div className="col-auto">
                    <div className="data-icon"><PiMapPinArea /></div>
                  </div>
                  <div className="col">
                    <h4>E-commerce Data</h4>
                    <p>Extract detailed product information, customer reviews, and ratings to perform thorough e-commerce analysis to optimize your online offerings.</p>
                    <Link className="site-link mt-4">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="shout">
        <div className="container">
          <h2 className="text-center">Shoutout From Our Clients</h2>
          <h5 className="text-center pt-4">Real Stories of the Success and Premium Satisfaction</h5>
          <div className="mt-5">
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
              <Masonry gutter="1.5rem">

                <div className="feed-box">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

                <div className="feed-box primary">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“Great communication and work. I'm very happy and will continue to work with Zakirsoft”</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

                <div className="feed-box secondary">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“He was very cooperative and did the job perfectly. I will work with him again in future.</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

                <div className="feed-box tertiary">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“working with zakir18 is a good experiece! / good communication, reasonable price!”</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

                <div className="feed-box alcohol">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“Working with zakir18 was a great experience, understanding person, fast response time dose his work with honestly, experienced, a problem solver. Gives it it's all. I highly recommend him for providing beyond of high quality service.”</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

                <div className="feed-box warning">
                  <div className="quote"><PiQuotesDuotone /></div>
                  <p>“Zakirsoft is one of the BEST web designers I've ever worked with professionally. I am a repeat customer who continues to work with Zakir because of his talent/skills, great customer service, work ethic, and attention to detail. I highly recommend Zakir and his web design services.”</p>
                  <div className="row align-items-center mt-4">
                    <div className="col-auto">
                      <div className="feed-img"><img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg" alt="" /></div>
                    </div>
                    <div className="col ps-0">
                      <h6>Abhi Diwedi</h6>
                      <p className="p-0">CEO, AI Suites</p>
                    </div>
                  </div>
                </div>

              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Home;