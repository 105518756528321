import React, { useEffect, useMemo, useRef, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

import { FaRegTrashAlt, FaSpinner } from "react-icons/fa"
import { BsQuestionCircleFill } from "react-icons/bs"

import SearchResults from './SearchResults'
import { useDispatch } from 'react-redux'
import { addMapUrl, fetchLanguage, onFetchCountries, onFetchGoogleData, onFetchLanguage, onFetchMapData } from '../../Redux/Actions/AuthActions'
import GoogleCard from './GoogleCard'
import { Autocomplete, GoogleMap, LoadScript, Marker, useJsApiLoader, useLoadScript } from '@react-google-maps/api'
import Layout from '../../Layout'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

function GoogleSearchData() {
  const dispatch = useDispatch()
  // const [addMapUrl, setAddMapUrl] = useState({
  //   google_map_urls: [],
  //   keyword: "",
  //   language: "",
  //   count: "",
  //   email_check: "",
  //   social_media_check: ""
  // })
  const [lang, setLang] = useState([])
  const location = useLocation();

  const [country, setCountry] = useState([])
  const [currentTodo, setCurrentTodo] = useState([]);
  const [loader, setLoader] = useState(false)
  const [searchResult, setSearchResult] = useState("");
  const { sId } = queryString.parse(location.search);

  const [url, setUrl] = useState({
    keyword: "",
    location: "",
    latitude: "",
    longitude: "",
  })
  const libraries = useMemo(() => ["places"], []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "emailMedia") {
      setUrl({ ...url, [name]: checked })

    } else {
      setUrl({ ...url, [name]: value })
    }
  }
  const locationRef = useRef(null);

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const googleList = (e) => {
    e.preventDefault()

    let data = {
      keyword: url.keyword,
      location: url.location
    }
    setLoader(true)
    dispatch(onFetchGoogleData(data, setCurrentTodo, setLoader))
    // setUrl({
    //   keyword: "",
    //   location: "",
    // })

  }
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: "AIzaSyBDR_JpjuxybgfEJuciljUjBKtTXOrCFB4",
    googleMapsApiKey: "AIzaSyCgWcuZtgkBF-7V9zZma4cDDi1kPt11mqw",
    libraries: libraries,
  });
  // const handleAddUrl = () => {
  //   if (url.urlValue && url.urlValue.includes("https://maps.google.com")) {
  //     setUrl({
  //       ...url,
  //       google_map_urls: [...url.google_map_urls, url.urlValue],
  //       urlValue: ""
  //     })
  //   }
  // };
  // const handleDelete = (index) => {
  //   let arr = url.google_map_urls.filter((val, i) => i !== index)
  //   setUrl({
  //     ...url,
  //     google_map_urls: arr
  //   })

  // };
  // const handleSearch = () => {
  //   let data = {
  //     google_map_urls: url.google_map_urls,
  //     keyword: url.keyword,
  //     language: url.language,
  //     count: url.count,
  //     email_check: url.email_check,
  //     social_media_check: url.social_media_check,
  //     country: url.country,
  //     email_and_social_media_check: url.emailMedia
  //   }
  //   dispatch(addMapUrl(data))
  // }
  const fetchLanguage = () => {
    dispatch(onFetchLanguage(setLang))
  }
  const fetchCountry = () => {
    dispatch(onFetchCountries(setCountry))
  }

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };
  const onPlaceChanged = () => {
    // if (searchResult != null) {
    //   const place = searchResult.getPlace();
    //   if (place?.geometry?.location) {
    //     const lat = place.geometry.location.lat();
    //     const lng = place.geometry.location.lng();
    //     const location = locationRef?.current?.value || place.formatted_address;
    //     console.log({ location, place })

    //     setUrl({
    //       ...url,
    //       latitude: lat,
    //       longitude: lng,
    //       location: location,
    //     });
    //   }
    // }

    if (searchResult) {
      const place = searchResult.getPlace();
      if (place?.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const location = locationRef.current?.value || place.formatted_address;
        setUrl(prevUrl => ({
          ...prevUrl,
          latitude: lat,
          longitude: lng,
          location: location,
        }));
      }
    }
  };

  useEffect(() => {
    fetchLanguage()
    fetchCountry()
  }, [])
  const fetchLeadData = () => {

    setLoader(true)
    dispatch(onFetchMapData(sId, setCurrentTodo, setLoader))
  }
  useEffect(() => {

    if (sId) {
      fetchLeadData()
    }
  }, [sId])
  return (
    <div>
      <Layout>

        <div className="page-container">
          <div className="container">
            {sId ? "" : <div className="data-page">
              <h4 className='page-title'><img src={require("../../images/icon/g-map.svg").default} alt="" /> Google Maps Search Data</h4>

              <form onSubmit={googleList}>
                <div className="input-wrap mt-5">
                  <label className='label' htmlFor="">Keywords:</label>

                  <input className='input' type="text" placeholder='Enter your keyword' name="keyword" value={url.keyword} onChange={handleChange} required />
                </div>


                <div className="input-wrap mt-5">
                  <label className='label' htmlFor="">Location:</label>
                  {isLoaded ? (
                    <Autocomplete
                      onPlaceChanged={onPlaceChanged}
                      onLoad={onLoad}
                    >
                      <input
                        className='input'
                        placeholder="Enter Location"
                        required
                        name="location"
                        onChange={(e) => setUrl(prevUrl => ({ ...prevUrl, location: e.target.value }))}
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      />
                    </Autocomplete>
                  ) : null}


                </div>





                <button className='site-link lg dark-blue mt-5' type='submit'>Start Search
                </button>
              </form>
            </div>
            }

            {loader ?
              <div style={{ fontSize: "30px", textAlign: "center" }} >
                <FaSpinner className="spin ms-1" />

              </div>
              : <div className="row">
                {currentTodo.length > 0 ? (
                  currentTodo.map((curElem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <GoogleCard
                          curElem={curElem}
                          sId={sId}
                          currentTodo={currentTodo}
                          setCurrentTodo={setCurrentTodo}
                          isLoaded={isLoaded}
                        />
                      </React.Fragment>

                    )
                  })
                ) : ""}

              </div>}

          </div>

        </div>




      </Layout>
    </div>
  )
}

export default GoogleSearchData