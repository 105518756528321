import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import SearchResults from './SearchResults'
import Layout from '../../Layout'

function InstagramFollower() {
    return (
        <div>
            <Layout>
                <div className="page-container">
                    <div className="container">
                        <div className='data-page'>
                            <h4 className='page-title'><img src={require("../../images/icon/insta2.svg").default} alt="" /> Search Instagram Followers</h4>

                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className="input-wrap mt-5">
                                        <label className='label' htmlFor="">Instagram Username:</label>
                                        <input className='input' type="text" placeholder='Enter Instagram Username' />
                                    </div>
                                    <button className='site-link full lg dark-blue mt-3'>Search User</button>
                                    <div className="input-wrap mt-5">
                                        <label className='label' htmlFor="">Scrape Follower Count:</label>
                                        <input className='input' type="number" placeholder='2000' />
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='user-card-info mt-5'>
                                        <div className='row'>
                                            <div className='col-auto'>
                                                <div className='user-card-img'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></div>
                                            </div>
                                            <div className='col'>
                                                <div>
                                                    <h5>Daniel Struard</h5>
                                                    <p>@david_struard</p>
                                                    <p className='pt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                                    <div className='row mt-4'>
                                                        <div className='col'>
                                                            <h5>30,215</h5>
                                                            <p>Followers</p>
                                                        </div>
                                                        <div className='col'>
                                                            <h5>1,162</h5>
                                                            <p>Followings</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-center'><button className='site-link lg dark-blue mt-5'>Scrape Search</button></div>

                            <SearchResults />
                        </div>
                    </div>
                </div>
            </Layout>        </div>
    )
}

export default InstagramFollower