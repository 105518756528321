import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

import SearchResults from './SearchResults'
import Layout from '../../Layout'

function MapSearch() {
  return (
    <div>
      <Layout>
        <div className="page-container">
          <div className="container">
            <div className="data-page">
              <h4 className='page-title'><img src={require("../../images/icon/map.svg").default} alt="" /> Get Maps Search Data</h4>

              <div className='map-holder'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224096.98009531654!2d76.92842322685051!3d28.644285209616267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1724054353077!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>

              <div className="input-wrap mt-5">
                <label className='label' htmlFor="">Keywords:</label>
                <input className='input' type="text" placeholder='Enter your keyword' />
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className="input-wrap mt-5">
                    <label className='label' htmlFor="">Rating Count:</label>
                    <select className='input' name="" id="">
                      <option value="">Select Rating</option>
                      <option value="">Rating -1</option>
                      <option value="">Rating -2</option>
                      <option value="">Rating -3</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="input-wrap mt-5">
                    <label className='label' htmlFor="">Count:</label>
                    <input className='input' type="number" placeholder='Enter your keyword' />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="input-wrap mt-5">
                    <label className='label' htmlFor="">Has Website:</label>
                    <select className='input' name="" id="">
                      <option value="">Yes</option>
                      <option value="">No</option>
                    </select>
                  </div>
                </div>
              </div>

              <h6 className='pt-4'>Scraping <span className='txt-orange'>22</span> URLs for keyword “<span className='txt-orange'>AI Stuffs</span>” for <span className='txt-orange'>100</span> credits.</h6>

              <div className='text-center'><button className='site-link lg dark-blue mt-5'>Start Search</button></div>


              <SearchResults />

            </div>
          </div>
        </div>
      </Layout>    </div>
  )
}

export default MapSearch