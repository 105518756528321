import React from 'react'
import { BsGlobe } from 'react-icons/bs'
import { CiMail } from 'react-icons/ci'
import { FiPhone } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { onDeleteLeads } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'

const LeadCard = ({ item, fetchLeadData }) => {
    const dispatch = useDispatch()

    const handleDeleteById = () => {

        let data = {
            id: item.id
        }
        dispatch(onDeleteLeads(data, fetchLeadData, false))
    }
    return (
        <tbody>
            <tr>
                {/* <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="checked" />
                    </div>
                </td> */}
                <td className=''>
                    {item.businessName}</td>
                <td>
                    <div class="detailTd lead_data">
                        <div class="lead_content">
                            <small> <FiPhone /></small>
                            <small> {item.phoneNumber ? item.phoneNumber : "N/A"}</small>
                        </div>
                        <div class="lead_content">
                            <small><CiMail /></small>
                            <small> {item.emails ? item.emails : "N/A"}</small>
                        </div>
                        <div class="lead_content">
                            <span style={{ borderBottom: "none" }}>
                                <small> <BsGlobe /></small>
                                <small>
                                    <span >{item.website ? item.website : "N/A"}</span>
                                </small>
                            </span>
                        </div>
                    </div>
                </td>

                {/* <td>{item.status}</td> */}
                <td>
                    <span>
                        <a href={item.mapUrl}>View</a>
                    </span>
                </td>
                <td>{item.userRatingsTotal}</td>
                <td>
                    <MdDelete fontSize={20} onClick={handleDeleteById} style={{ cursor: "pointer" }} />
                </td>
            </tr>

        </tbody>
    )
}

export default LeadCard