import { combineReducers } from "redux";
// import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { AlertReducer } from "./AlertReducer";
// import { RebrandingReducer } from "./RebrandingReducer";
// import { HelpReducer } from "./HelpReducer";
// import { SocialReducer } from "./SocialReducer";
// import AgentReducer from "./AgentReducer";
// import { CampaignReducer } from "./CampaignReducer";
// import { ExtraReducer } from "./ExtraReducer";


const appReducer = combineReducers({
    auth: AuthReducer,
    alert: AlertReducer,
    // rebranding: RebrandingReducer,
    // help: HelpReducer,
    // social: SocialReducer,
    // agent: AgentReducer,
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;