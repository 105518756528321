import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { Dropdown } from "react-bootstrap";
import { FiLogOut, FiUser } from "react-icons/fi";

function Header() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(logoutUser(navigate, setLoader));
  };

  return (
    <header className="site-header">
      <div className="container">
        <div className="site-header-in">
          <div className="logo">
            <Link to="/">
              <img src={require("../../images/logo.svg").default} alt="" />
            </Link>
          </div>
          <nav className="site-nav">
            <ul>
              {/* <li>
                <NavLink
                  to="/dashboard"
                  isActive={() => location.pathname === "/dashboard"}
                >
                  Dashboard
                </NavLink>
                {location.pathname === "/dashboard" && <hr />}
              </li> */}
              <li><NavLink to="/dashboard" className={location.pathname === "/dashboard" || location.pathname === "/" ? "inner-active" : ""}>Dashboard</NavLink></li>

              {/* <li>
                <NavLink
                  to="/sources"
                  isActive={() => location.pathname === "/sources"}
                >
                  Sources
                </NavLink>
                {location.pathname === "/sources" || location.pathname === "/google-map" && <hr />}
              </li> */}
              <li><NavLink to="/sources" className={location.pathname === "/sources" || location.pathname === "/google-map" || location.pathname === "/website-email" ? "inner-active" : ""}>Sources</NavLink></li>

              {/* <li>
                <NavLink
                  to="/results-card"
                  isActive={() => location.pathname === "/results-card"}
                >
                  Results
                </NavLink>
                {location.pathname === "/results-card" && <hr />}
              </li> */}
              <li><NavLink to="/results-card" className={location.pathname === "/results-card" ? "inner-active" : ""}>Results</NavLink></li>

              <li>
                <div className="profile-menu">
                  <Dropdown>
                    <Dropdown.Toggle variant="successN" className="drop-btn">
                      <img alt="" src={auth.user.profile} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <NavLink to="/my-account"><FiUser /> My Account</NavLink>
                      <a className="cursor-pointer" onClick={handleLogout}><FiLogOut /> Logout</a>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header >
  );
}

export default Header;
