import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { Link } from 'react-router-dom'
import Layout from '../../Layout'

function Sources() {
    return (

        <Layout>

            <div className="page-container">
                <div className="container">
                    <h2 className="text-center">Choose Your Data Sources</h2>
                    {/* <div className="search-wrap">
                            <span className="search-icon"><img src={require("../../images/icon/search.svg").default} alt="" /></span>
                            <input className="search-inp" type="text" placeholder='Search data source your are looking for...' />
                        </div> */}
                    <div className='search-grid'>
                        <ul>
                            <li>
                                <Link to="/google-map">
                                    <div className='search-grid-single'>
                                        <div className="row h-100 align-items-center">
                                            <div className="col-auto">
                                                <div className="search-grid-icon"><img src={require("../../images/icon/g-map.svg").default} alt="" /></div>
                                            </div>
                                            <div className="col">
                                                <h4>Google Maps Search Data</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/linkedin-profile?type=insta">
                                    <div className='search-grid-single'>
                                        <div className="row h-100 align-items-center">
                                            <div className="col-auto">
                                                <div className="search-grid-icon"><img src={require("../../images/icon/linkedin2.svg").default} alt="" /></div>
                                            </div>
                                            <div className="col">
                                                <h4>Linkedin Profile Search Data</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/linkedin-company">
                                    <div className='search-grid-single'>
                                        <div className="row h-100 align-items-center">
                                            <div className="col-auto">
                                                <div className="search-grid-icon"><img src={require("../../images/icon/linkedin2.svg").default} alt="" /></div>
                                            </div>
                                            <div className="col">
                                                <h4>Linkedin Company Search Data</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/website-email">
                                    <div className='search-grid-single'>
                                        <div className="row h-100 align-items-center">
                                            <div className="col-auto">
                                                <div className="search-grid-icon"><img src={require("../../images/icon/dribble.svg").default} alt="" /></div>
                                            </div>
                                            <div className="col">
                                                <h4>Website Email Search Data</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            {/* <li>
                                    <Link to="/map-search">
                                        <div className='search-grid-single'>
                                            <div className="row h-100 align-items-center">
                                                <div className="col-auto">
                                                    <div className="search-grid-icon"><img src={require("../../images/icon/map.svg").default} alt="" /></div>
                                                </div>
                                                <div className="col">
                                                    <h4>Get Maps Search Data</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li> */}
                            <li>
                                <Link to="/instagram-follower">
                                    <div className='search-grid-single'>
                                        <div className="row h-100 align-items-center">
                                            <div className="col-auto">
                                                <div className="search-grid-icon"><img src={require("../../images/icon/insta2.svg").default} alt="" /></div>
                                            </div>
                                            <div className="col">
                                                <h4>Search Instagram Follower List</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default Sources