import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';

function Pricing() {
    return (
        <div>
            {/* <Header /> */}

            <div className="page-container">
                <div className="container">
                    <h2 className='text-center'>Choose Pricing Plan</h2>
                    <div className="row justify-content-center">
                        <div className="col-sm-7">
                            <p className='text-center pt-3'>Select the perfect pricing plan that suits your budget and requirements, offering you flexibility and value. Our range of options ensures that you find the right balance between cost and features</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                            <Nav variant="" className="price-nav">
                                <div className='price-nav-in'>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            Monthly
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            Yearly <span className='save-tag'>SAVE 15%</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='price-group'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="price-card">
                                                    <h5>Free</h5>
                                                    <p className='pt-4'>For individuals scrapping profiles</p>
                                                    <h3 className='pt-3'>$0</h3>
                                                    <p className='pt-1'>Free forever</p>
                                                    <Link className='site-link lg simple full mt-4 white'>Join for free</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="price-card starter">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-auto">
                                                            <h5>Starter</h5>
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className='save-tag'>SAVE 15%</span>
                                                        </div>
                                                    </div>
                                                    <p className='pt-4'>For mid-level companies data need demand</p>
                                                    <h3 className='pt-3'>$32</h3>
                                                    <p className='pt-1'>Per member/month</p>
                                                    <Link className='site-link lg simple full mt-4 black'>Try it for free for 7 days</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="price-card premium">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-auto">
                                                            <h5>Premium</h5>
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className='save-tag'>SAVE 15%</span>
                                                        </div>
                                                    </div>
                                                    <p className='pt-4'>For large-scale companies crafted data need</p>
                                                    <h3 className='pt-3'>$55</h3>
                                                    <p className='pt-1'>Per member/month</p>
                                                    <Link className='site-link lg simple full mt-4'>Try it for free for 7 days</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='price-group'>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="price-card">
                                                    <h5>Free</h5>
                                                    <p className='pt-4'>For individuals scrapping profiles</p>
                                                    <h3 className='pt-3'>$0</h3>
                                                    <p className='pt-1'>Free forever</p>
                                                    <Link className='site-link lg simple full mt-4 white'>Join for free</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="price-card starter">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-auto">
                                                            <h5>Starter</h5>
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className='save-tag'>SAVE 15%</span>
                                                        </div>
                                                    </div>
                                                    <p className='pt-4'>For mid-level companies data need demand</p>
                                                    <h3 className='pt-3'>$32</h3>
                                                    <p className='pt-1'>Per member/month</p>
                                                    <Link className='site-link lg simple full mt-4 black'>Try it for free for 7 days</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="price-card premium">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-auto">
                                                            <h5>Premium</h5>
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className='save-tag'>SAVE 15%</span>
                                                        </div>
                                                    </div>
                                                    <p className='pt-4'>For large-scale companies crafted data need</p>
                                                    <h3 className='pt-3'>$55</h3>
                                                    <p className='pt-1'>Per member/month</p>
                                                    <Link className='site-link lg simple full mt-4'>Try it for free for 7 days</Link>
                                                    <hr className='mt-4 mb-4' />
                                                    <h6 className='pb-2'>Features</h6>
                                                    <ul className='feature-list'>
                                                        <li>3,000 Credits per month</li>
                                                        <li>0.01 per additional credit</li>
                                                        <li>Google Maps Search Data</li>
                                                        <li>2 URL Google Maps Search</li>
                                                        <li>Website Data Email Search</li>
                                                        <li>Website Data Email Search</li>
                                                    </ul>
                                                    <div className='mt-5'><Link>+ More awesome features</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    )
}

export default Pricing