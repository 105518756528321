import React, { useState } from 'react'
import ResultsRowCard from './ResultsRowCard'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { BsFolder, BsPencil, BsTrash3 } from 'react-icons/bs'
import Header from '../Common/Header'
import { LuEye } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { BsGlobe } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ResultModal from './ResultModal';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { onDeleteFolder, onDeleteLeads } from '../../Redux/Actions/AuthActions'
import LeadCard from './LeadCard'
const ResultAccordian = ({ curElem, index, fetchLeadData, leads, setLeads }) => {
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(true);
    const [selected, setSelected] = useState(null)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (e) => {
        setChecked(e.target.checked)
    }
    const [loader, setLoader] = useState(true)

    const handleDelete = () => {
        let data = {
            id: curElem.sourceId
        }
        dispatch(onDeleteFolder(data, fetchLeadData, false))
    }


    // const handleDeleteById = () => {
    //     const filtered = curElem?.clients?.filter((item) => +item.id === +selected)
    //     console.log(filtered, "filtered")
    //     // dispatch(onDeleteLeads())
    // }
    return (
        <Accordion className='result_accordion'>
            <Accordion.Item eventKey={index}>
                <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 ps-2 pe-2">
                        <div>
                            <BsFolder fontSize={17} className='me-2' />
                            <span>{curElem.folderName}</span>
                        </div>
                        <div className='folderLinlk'>
                            <Link to={`/google-map?sId=${curElem.sourceId}`}> <LuEye fontSize={17} /></Link>
                            {+auth.user.isClientAccount === 1 ? null :
                                <>
                                    <BsPencil onClick={handleShow} fontSize={17} />
                                    <BsTrash3 onClick={handleDelete} fontSize={17} />
                                </>
                            }

                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <table class="table widget-table">
                        <thead>
                            <tr>
                                {/* <th scope="col" className='check_head'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </th> */}
                                <th scope="col">Business Name</th>
                                <th scope="col">Contact Details</th>
                                {/* <th scope="col">Status</th> */}
                                <th scope="col">GBP Listing</th>
                                <th scope="col">Total Reviews</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        {curElem?.clients?.map((item, index) => {
                            // console.log(item, "checkedchecked")

                            return <LeadCard item={item} fetchLeadData={fetchLeadData} />
                        })}

                    </table>
                </Accordion.Body>
            </Accordion.Item>
            <ResultModal handleClose={handleClose} show={show} curElem={curElem} fetchLeadData={fetchLeadData} loader={loader} setLoader={setLoader} leads={leads} setLeads={setLeads} />

        </Accordion >)
}

export default ResultAccordian