import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const loginUser = (data, setLoader) => (dispatch) => {
    localStorage.clear();
    commonAxios("login", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGIN_USER', payload: res.data });

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}


export const logoutUser = (navigate, setLoader) => (dispatch, getState) => {
    // let token = localStorage.getItem("token");
    // console.log(token, "tokenn")
    commonAxios("logout", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGOUT' });
                // dispatch(setAlert(res.msg, "success"));
                localStorage.clear()
                navigate('/login');
            }
            else {
                // dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);

        })
        .catch((err) => {
            console.log(err)
            setLoader(false);

        });
};
export const masterLoginUser = (user, setLoader, navigate) => (dispatch) => {
    commonAxios("master-login", user, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                navigate('/');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const resetPassword = (data, setLoader) => (dispatch) => {
    commonAxios("forgot-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const addMapUrl = (data) => (dispatch, getState) => {
    commonAxios("add-map-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            // if (setLoader) {
            //     setLoader(false)
            // }
        })
        .catch((err) => {
            console.log(err)

            // if (setLoader) {
            //     setLoader(false)
            // }
        });
};



export const SearchMapUrl = (data, setMapUrl) => (dispatch, getState) => {
    commonAxios("search-map", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMapUrl(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            // if (setLoader) {
            //     setLoader(false)
            // }
        })
        .catch((err) => {
            console.log(err)

            // if (setLoader) {
            //     setLoader(false)
            // }
        });
};
// export const fetchMapUrl = (data, setMapUrl) => (dispatch, getState) => {
//     commonAxios("fetch-map-url", {}, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 setMapUrl(res.data)
//             } else {

//             }
//             // if (setLoader) {
//             //     setLoader(false)
//             // }
//         })
//         .catch((err) => {
//             console.log(err)

//             // if (setLoader) {
//             //     setLoader(false)
//             // }
//         });
// };


export const linkedinProfile = (url, data, setWebData, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setWebData(res.data)
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};



export const linkedin_Company_Profile = (data, setLoader) => (dispatch, getState) => {
    commonAxios("linkedin-company-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const websiteEmail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("website-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};




export const onFetchLanguage = (setLang) => (dispatch, getState) => {
    commonAxios("fetch-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLang(res.data)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                // dispatch(setAlert(res.msg, "danger"));

            }
            // if (setLoader) {
            //     setLoader(false)
            // }
        })
        .catch((err) => {
            console.log(err)

            // if (setLoader) {
            //     setLoader(false)
            // }
        });
};

export const onFetchCountries = (setLang) => (dispatch, getState) => {
    commonAxios("fetch-countries", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLang(res.data)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                // dispatch(setAlert(res.msg, "danger"));

            }
            // if (setLoader) {
            //     setLoader(false)
            // }
        })
        .catch((err) => {
            console.log(err)

            // if (setLoader) {
            //     setLoader(false)
            // }
        });
};
export const onFetchGoogleData = (data, setCurrentTodo, setLoader) => (dispatch, getState) => {
    commonAxios("find-leads", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                let arr = res.data.map((item) => {
                    return {
                        ...item,
                        openingHours: (item.openingHours && typeof item.openingHours === 'string') ? JSON.parse(item.openingHours) : (item.openingHours || []),
                        photos: (item.photos && typeof item.photos === 'string') ? JSON.parse(item.photos) : (item.photos || []),
                        review: (item.review && typeof item.review === 'string') ? JSON.parse(item.review) : (item.review || []),
                        loader: true
                    }
                })
                setCurrentTodo(arr)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                // dispatch(setAlert(res.msg, "danger"));

            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
            // dispatch(setAlert(err.message, "danger"))
        })
}
export const onSearchGoogleData = (data, currentTodo, setCurrentTodo, setGoogleData) => (dispatch, getState) => {
    commonAxios("search-leads-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.map((item) => {
                    return {
                        ...item,
                        openingHours: (item.openingHours && typeof item.openingHours === 'string') ? JSON.parse(item.openingHours) : (item.openingHours || []),
                        photos: (item.photos && typeof item.photos === 'string') ? JSON.parse(item.photos) : (item.photos || []),
                        review: (item.review && typeof item.review === 'string') ? JSON.parse(item.review) : (item.review || []),
                        loader: true
                    }
                })
                let currDataIndex = currentTodo.findIndex((val) => +val.id === +data.id);
                let cur = currentTodo[currDataIndex];
                setCurrentTodo(prevTodo => {
                    const updatedTodo = [...prevTodo];
                    if (currDataIndex !== -1 && cur) {
                        updatedTodo[currDataIndex] = {
                            ...cur,
                            ...arr,
                            loader: false
                        };
                    } else {
                        console.log("Item not found in currentTodo array.");
                        // dispatch(setAlert(res.msg, "danger"));

                    }
                    setGoogleData(arr);
                    return updatedTodo;


                });
                // dispatch(setAlert(res.msg, "success"));

            } else {
                // dispatch(setAlert(res.msg, "danger"));

            }
        }).catch((err) => {
            console.log(err)
            // setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const websiteScrapper = (datum, setWebsiteScrap, setLoader) => (dispatch, getState) => {
    commonAxios("website-scrapper", datum, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setWebsiteScrap(res.data)
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const onFetchLeads = (setLeads, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-leads", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLeads(res.data)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                setLeads([])

                // dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const onEditFolder = (data, handleClose, setLoader, leads, setLeads) => (dispatch, getState) => {
    commonAxios("edit-folder", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                let arr = leads.sources?.map((item) => {
                    if (+item.sourceId === +data.id) {
                        return { ...item, folderName: res.data.folderName }
                    } else {
                        return item
                    }
                })
                setLeads({ sources: arr })
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};

// export const onEditFolder = (data, handleClose, fetchLeadData, setLoader) => (dispatch, getState) => {
//     setLoader(true); // Start loading before the API call
//     commonAxios("edit-folder", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch({ type: "CHANGE_FOLDER_NAME", payload: { data } });
//                 dispatch(setAlert(res.msg, "success"));
//             } else {
//                 dispatch(setAlert(res.msg, "danger"));
//             }
//             handleClose();
//             fetchLeadData()
//         })
//         .catch((err) => {
//             console.log(err);
//             dispatch(setAlert(err.message, "danger"));
//         })
//         .finally(() => {
//             setLoader(false); // Ensure loader stops in finally
//         });
// };


export const onDeleteFolder = (data, fetchLeadData, setLoader) => (dispatch, getState) => {
    commonAxios("delete-folder", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                console.log(res)

                fetchLeadData()
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const onDeleteLeads = (data, fetchLeadData, setLoader) => (dispatch, getState) => {
    commonAxios("delete-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                console.log(res)

                fetchLeadData()
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const onUpdatePassword = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-user-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                password: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                password: false
            })
        })
}

export const onUpdateUser = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data === 1) {
                    dispatch(logoutUser())
                }
                dispatch({ type: "CHANGE_USER_NAME", payload: data })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                user: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                user: false
            })
        })
}


export const onFetchMapData = (sId, setLeads, setLoader) => (dispatch, getState) => {
    commonAxios(`fetch-sources/${sId}`, {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.map((item) => {
                    return {
                        ...item,
                        openingHours: (item.openingHours && typeof item.openingHours === 'string') ? JSON.parse(item.openingHours) : [],
                        photos: (item.photos && typeof item.photos === 'string') ? JSON.parse(item.photos) : (item.photos || []),
                        review: (item.review && typeof item.review === 'string') ? JSON.parse(item.review) : (item.review || []),
                    }
                })
                console.log(arr, "heelo")
                setLeads(arr)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                setLeads([])

                // dispatch(setAlert(res.msg, "danger"));

            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};


export const changeProfile = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-profile-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                image: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                image: false
            })
        })
}

