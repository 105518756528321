import { produce } from "immer"
let auth = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).auth : {
    isAuthenticated: false,
    token: false,
    user: false,
}
const initialState = {
    token: auth.token,
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
    data: [],
}

export const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'LOGIN_USER':
            return produce(state, (draft) => {
                draft.isAuthenticated = true
                draft.token = action.payload.token
                draft.user = action.payload.user
            })

        case 'LOGOUT':
            localStorage.clear()
            return produce(state, (draft) => {
                draft.isAuthenticated = false
                draft.token = false
                draft.user = false

            })
        case 'CHANGE_USER_IMAGE':
            return produce(state, (draft) => {
                draft.user.profile = action.payload
            })

        case 'CHANGE_USER_NAME':
            return produce(state, (draft) => {
                draft.user.name = action.payload.name
            })

        case 'CHANGE_USER_EMAIL':
            return produce(state, (draft) => {
                draft.user.email = action.payload.email
            })
        case 'LOAD_USER_DATA':
            return produce(state, (draft) => {
                draft.user = action.payload
            })
        // case "CHANGE_FOLDER_NAME":
        //     return produce(state, (draft) => {
        //         const folderIndex = draft.data.findIndex((val) => +val.sourceId === +action.payload.data.id);
        //         if (folderIndex !== -1) { // Check if folder exists
        //             draft.data[folderIndex].folderName = action.payload.data.name; // Ensure this matches the payload
        //         }
        //     });

        default:
            return {
                ...state
            }
    }
}