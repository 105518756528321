

import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { useDispatch } from 'react-redux'
import { linkedinProfile, websiteScrapper } from '../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import SearchResults from '../Sources/SearchResults'

const WebsiteEmail = () => {
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const [error, setError] = useState('');
  const [webData, setWebData] = useState("")
  const [state, setState] = useState({
    url: ""
  })
  const [websiteScrap, setWebsiteScrap] = useState([])
  const validateURL = (url) => {
    const urlPattern = /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)$/i;
    return urlPattern.test(url);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value })
  }
  const handleSearch = (e) => {
    e.preventDefault()
    if (!validateURL(state.url)) {
      setError('Please enter a valid URL.');
      return;
    }
    let data = {
      website_email: state.url
    }
    setError('');
    setLoader(true)
    dispatch(linkedinProfile('website-email', data, setWebData, setLoader))
  }
  const websiteData = () => {

    let datum = {
      id: webData
    }
    setLoader(true)
    dispatch(websiteScrapper(datum, setWebsiteScrap, setLoader))
  }
  useEffect(() => {
    if (webData) {
      websiteData()

    }
  }, [webData])

  return (
    <div>
      <div className="page-container">
        <div className="container">

          <div className='data-page'>
            <form onSubmit={handleSearch}>
              <h4 className='page-title'><img src={require("../../images/icon/linkedin2.svg").default} alt="" />"Website Email Search Data</h4>
              <div className="input-wrap mt-5">
                <label className='label' htmlFor="">Enter Company Url:</label>
                <textarea name="url" className='input' id="" placeholder='Enter Profile URLs (comma separated, multiple allowed, duplicates will be removed)' onChange={handleChange} value={state.url} required ></textarea>
                {error && <p className='error-text'>{error}</p>}

              </div>
              {/* <h6 className='pt-4'>Scraping <span className='txt-orange'>22</span> URLs  for <span className='txt-orange'>100</span> credits.</h6> */}

              <div className='text-center'><button className='site-link lg dark-blue mt-5'>Start Search{loader ? <FaSpinner className="spin ms-1" /> : null}</button></div>
            </form>
            {/* <SearchResults /> */}
            {websiteScrap.map((item) => {
              return <div>{item.emails.join(", ")}</div>;
            })}
          </div>

        </div>
      </div>

    </div>
  )

}

export default WebsiteEmail