import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { useDispatch } from 'react-redux'
import { linkedinProfile, websiteScrapper } from '../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import SearchResults from '../Sources/SearchResults'
// const isValidUrl = (url, type) => {
//     const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([\/\w .-]*)*\/?$/;

//     switch (type) {
//         case 'insta':
//             return urlPattern.test(url) && url.includes('instagram.com');
//         case 'linkedin_profiles':
//             return urlPattern.test(url) && url.includes('linkedin.com/in/');
//         case 'linkedin_company_profiles':
//             return urlPattern.test(url) && url.includes('linkedin.com/company/');
//         case 'website_email':
//             return urlPattern.test(url);
//         default:
//             return false;
//     }
// };
const SearchData = ({ heading, names, type }) => {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const [error, setError] = useState('');
    const [webData, setWebData] = useState("")
    const [state, setState] = useState({
        url: ""
    })
    const [websiteScrap, setWebsiteScrap] = useState([])
    const handleChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value })
    }
    const handleSearch = (e) => {
        e.preventDefault()

        let url = ""
        let data = {}
        if (type === "insta") {
            url = "insta"
            data = {
                insta: state.url
            }
        } else if (type === "linkedin_profiles") {
            url = "linkedin-profile"
            data = {
                linkedin_profiles: state.url
            }
        } else if (type === "linkedin_company_profiles") {
            url = "linkedin-company-profile"
            data = {
                linkedin_company_profiles: state.url
            }
        } else if (type === "website_email") {
            url = "website-email"
            data = {
                website_email: state.url
            }
        }

        setLoader(true)
        dispatch(linkedinProfile(url, data, setWebData, setLoader))
    }
    const websiteData = () => {
        let datum = {
            id: webData
        }
        console.log(datum, "datum")
        dispatch(websiteScrapper(datum, setWebsiteScrap))
    }
    useEffect(() => {
        if (webData) {
            websiteData()

        }
    }, [webData])

    return (
        <div>
            {/* <Header /> */}
            <div className="page-container">
                <div className="container">

                    <div className='data-page'>
                        <form onSubmit={handleSearch}>
                            <h4 className='page-title'><img src={require("../../images/icon/linkedin2.svg").default} alt="" />{heading}</h4>
                            <div className="input-wrap mt-5">
                                <label className='label' htmlFor="">Enter {names} Url:</label>
                                <textarea name="url" className='input' id="" placeholder='Enter Profile URLs (comma separated, multiple allowed, duplicates will be removed)' onChange={handleChange} value={state.url} required ></textarea>
                                {/* {error && <p className='error-text'>{error}</p>} */}

                            </div>
                            {/* <h6 className='pt-4'>Scraping <span className='txt-orange'>22</span> URLs  for <span className='txt-orange'>100</span> credits.</h6> */}

                            <div className='text-center'><button className='site-link lg dark-blue mt-5'>Start Search{loader ? <FaSpinner className="spin ms-1" /> : null}</button></div>
                        </form>
                        {/* <SearchResults /> */}
                        {websiteScrap.map((item) => {
                            return <div>{item.emails}</div>;
                        })}
                    </div>

                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )

}

export default SearchData