import React from 'react'
import { Link } from 'react-router-dom'

function SearchResults() {
  const downloadPDF = () => {
    console.log("fmdfkgndfk")
    const url = 'https://example.com/yourfile.pdf';
    window.open(url, '_blank');
  };
  return (
    <div className='search-results' >
      <div className='text-center'>
        <div><img src={require("../../images/icon/search-round.svg").default} alt="" /></div>
        <h5 className='pt-4'><span className='txt-orange'>187</span> results found</h5>
        <h6 className='font-gray pt-3'>We have found the following results for your search criteria. <br /> Would you like to try again or explore something else?</h6>
        <div className='btn-block mt-4'>
          <button className='site-link lg simple' onClick={downloadPDF}><img src={require("../../images/icon/xlsx.svg").default} alt="" /> Download Excel</button>
          <button className='site-link lg simple'><img src={require("../../images/icon/txt.svg").default} alt="" /> Download JSON</button>
        </div>
        <p className='pt-4'>Need further support?<Link className='decoration'>Contact us</Link></p>
      </div>
    </div>
  )
}
export default SearchResults